import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';


function Copyright() {
    return (
        <Typography variant="body2" color="text.secondary">
            {'Copyright © '}
            <Link color="inherit" href={"/"}>
                FRPS
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const FeedbackLink = () => {
    return (
        <Typography variant="body2" color="text.secondary">
            <Link href={"/feedback"} variant="body2" color="inherit">
                {'Feedback'}
            </Link>
        </Typography>
    );
}

export default function StickyFooter() {
    return (
        <Box
            sx={{
                display: 'flex',
                bottom: 0,
                flexDirection: 'column',
                minHeight: '350px',
                mt: '60px',
                width: '100%'
            }}
        >
            <CssBaseline />
            <Box
                component="footer"
                sx={{
                    py: 3,
                    px: 2,
                    mt: 'auto',
                    backgroundColor: (theme) =>
                        theme.palette.mode === 'light'
                            ? theme.palette.grey[200]
                            : theme.palette.grey[800],
                }}
            >
                <Container>
                    <Typography variant="body1">
                        FRPS -- Helping you find your next home.
                    </Typography>
                    <Copyright />
                    <FeedbackLink />
                </Container>
            </Box>
        </Box>
    );
}