import apart1 from '../images/apart_1.jpg';
import apart2 from '../images/apart_2.jpg';
import apart3 from '../images/apart_3.jpg';
import apart4 from '../images/apart_4.jpg';
import apart5 from '../images/apart_5.jpg';
import apart6 from '../images/apart_6.jpg';
import apart7 from '../images/apart_7.jpg';
import apart8 from '../images/apart_8.jpg';
import apart9 from '../images/apart_9.jpg';
import apart10 from '../images/apart_10.jpg';
import apart11 from '../images/apart_11.jpg';
import apart12 from '../images/apart_12.jpg';
import apart13 from '../images/apart_13.jpg';
import apart14 from '../images/apart_14.jpg';
import apart15 from '../images/apart_15.jpg';
import apart16 from '../images/apart_16.jpg';
import apart17 from '../images/apart_17.jpg';
import apart18 from '../images/apart_18.jpg';
import apart19 from '../images/apart_19.jpg';
import apart20 from '../images/apart_20.jpg';
import apart21 from '../images/apart_21.jpg';

const images = [
    apart1,
    apart2,
    apart3,
    apart4,
    apart5,
    apart6,
    apart7,
    apart8,
    apart9,
    apart10,
    apart11,
    apart12,
    apart13,
    apart14,
    apart15,
    apart16,
    apart17,
    apart18,
    apart19,
    apart20,
    apart21,
]

export default images;